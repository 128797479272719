import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/register`,
        component: React.lazy(() => import('views/auth-views/authentication/register')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    }
]

export const protectedRoutes = [
    {
        key: 'dashboard.default',
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        component: React.lazy(() => import('views/app-views/dashboards/default')),
    },
    {
        key: 'users.users-list',
        path: `${APP_PREFIX_PATH}/users/users-list`,
        component: React.lazy(() => import('views/app-views/users')),
    },
    {
        key: 'users.users-groups',
        path: `${APP_PREFIX_PATH}/users/users-groups`,
        component: React.lazy(() => import('views/app-views/users/user-groups')),
    },
    {
        key: 'users.add-user',
        path: `${APP_PREFIX_PATH}/users/add-user`,
        component: React.lazy(() => import('views/app-views/users/add-user')),
    },

    {
        key: 'xnco-sale.products',
        path: `${APP_PREFIX_PATH}/xnco-sale/products`,
        component: React.lazy(() => import('views/app-views/xnco-sale/products')),
    },
    {
        key: 'xnco-sale.categories',
        path: `${APP_PREFIX_PATH}/xnco-sale/categories`,
        component: React.lazy(() => import('views/app-views/xnco-sale/categories')),
    },
    {
        key: 'xnco-sale.add-product',
        path: `${APP_PREFIX_PATH}/xnco-sale/add-product`,
        component: React.lazy(() => import('views/app-views/xnco-sale/products/add-product')),
    },
    {
        key: 'xnco-sale.add-product',
        path: `${APP_PREFIX_PATH}/xnco-sale/add-category`,
        component: React.lazy(() => import('views/app-views/xnco-sale/categories/add-category')),
    },
    {
        key: 'xnco-sale.sites',
        path: `${APP_PREFIX_PATH}/xnco-sale/sites`,
        component: React.lazy(() => import('views/app-views/xnco-sale/sites')),
    },
    {
        key: 'xnco-sale.add-site',
        path: `${APP_PREFIX_PATH}/xnco-sale/add-site`,
        component: React.lazy(() => import('views/app-views/xnco-sale/sites/add-site')),
    },
    {
        key: 'xnco-sale.markets',
        path: `${APP_PREFIX_PATH}/xnco-sale/markets`,
        component: React.lazy(() => import('views/app-views/xnco-sale/markets')),
    },
    {
        key: 'xcnco-sale.add-market',
        path: `${APP_PREFIX_PATH}/xnco-sale/add-market`,
        component: React.lazy(() => import('views/app-views/xnco-sale/markets/add-market')),
    },
    {
        key: 'xnco-sale.customers',
        path: `${APP_PREFIX_PATH}/xnco-sale/customers`,
        component: React.lazy(() => import('views/app-views/xnco-sale/customers')),
    },
    {
        key: 'xcnco-sale.add-customer',
        path: `${APP_PREFIX_PATH}/xnco-sale/add-market`,
        component: React.lazy(() => import('views/app-views/xnco-sale/customers/add-customer')),
    },
    {
        key: 'xnco-sale.departments',
        path: `${APP_PREFIX_PATH}/xnco-sale/departments`,
        component: React.lazy(() => import('views/app-views/xnco-sale/departments')),
    },
    {
        key: 'xcnco-sale.add-department',
        path: `${APP_PREFIX_PATH}/xnco-sale/add-department`,
        component: React.lazy(() => import('views/app-views/xnco-sale/departments/add-department')),
    },
    {
        key: 'xnco-sale.new-on-market',
        path: `${APP_PREFIX_PATH}/xnco-sale/new-on-market`,
        component: React.lazy(() => import('views/app-views/xnco-sale/new-on-market')),
    },
    {
        key: 'xnco-sale.salers',
        path: `${APP_PREFIX_PATH}/xnco-sale/salers`,
        component: React.lazy(() => import('views/app-views/xnco-sale/salers')),
    },
    {
        key: 'xnco-sale.product-returns',
        path: `${APP_PREFIX_PATH}/xnco-sale/product-returns`,
        component: React.lazy(() => import('views/app-views/xnco-sale/product-returns')),
    },
    {
        key: 'xnco-sale.discounts',
        path: `${APP_PREFIX_PATH}/xnco-sale/discounts`,
        component: React.lazy(() => import('views/app-views/xnco-sale/discounts')),
    },
    {
        key: 'xnco-sale.invoices',
        path: `${APP_PREFIX_PATH}/xnco-sale/invoices`,
        component: React.lazy(() => import('views/app-views/xnco-sale/invoices')),
    },
    {
        key: 'xnco-sale.market-reports',
        path: `${APP_PREFIX_PATH}/xnco-sale/market-reports`,
        component: React.lazy(() => import('views/app-views/xnco-sale/market-reports')),
    },
    {
        key: 'xnco-sale.saler-stock-availabilities',
        path: `${APP_PREFIX_PATH}/xnco-sale/saler-stock-availabilities`,
        component: React.lazy(() => import('views/app-views/xnco-sale/saler-stock-availabilities')),
    },

    //Vessel
    {
        key: 'vessel.clients',
        path: `${APP_PREFIX_PATH}/vessel/clients`,
        component: React.lazy(() => import('views/app-views/vessel/clients')),
    },
    {
        key: 'vessel.add-client',
        path: `${APP_PREFIX_PATH}/vessel/add-client`,
        component: React.lazy(() => import('views/app-views/vessel/clients/add')),
    },

    {
        key: 'vessel.drivers',
        path: `${APP_PREFIX_PATH}/vessel/drivers`,
        component: React.lazy(() => import('views/app-views/vessel/drivers')),
    },
    {
        key: 'vessel.add-driver',
        path: `${APP_PREFIX_PATH}/vessel/add-driver`,
        component: React.lazy(() => import('views/app-views/vessel/drivers/add')),
    },
    {
        key: 'vessel.ports',
        path: `${APP_PREFIX_PATH}/vessel/ports/*`,
        component: React.lazy(() => import('views/app-views/vessel/ports/PortActivities')),
    },
    {
        key: 'vessel.ports',
        path: `${APP_PREFIX_PATH}/vessel/manage-ports`,
        component: React.lazy(() => import('views/app-views/vessel/ports')),
    },
    {
        key: 'vessel.add-port',
        path: `${APP_PREFIX_PATH}/vessel/add-port`,
        component: React.lazy(() => import('views/app-views/vessel/ports/add')),
    },
    {
        key: 'vessel.tasks',
        path: `${APP_PREFIX_PATH}/vessel/tasks`,
        component: React.lazy(() => import('views/app-views/vessel/tasks')),
    },
    {
        key: 'vessel.add-task',
        path: `${APP_PREFIX_PATH}/vessel/add-task`,
        component: React.lazy(() => import('views/app-views/vessel/tasks/add')),
    },
    {
        key: 'vessel.vehicles',
        path: `${APP_PREFIX_PATH}/vessel/vehicles`,
        component: React.lazy(() => import('views/app-views/vessel/vehicles')),
    },
    {
        key: 'vessel.add-vehicle',
        path: `${APP_PREFIX_PATH}/vessel/add-vehicle`,
        component: React.lazy(() => import('views/app-views/vessel/vehicles/add')),
    },
    {
        key: 'vessel.ship-schedules',
        path: `${APP_PREFIX_PATH}/vessel/ship-schedules/*`,
        component: React.lazy(() => import('views/app-views/vessel/ship-schedules')),
    },
    {
        key: 'vessel.add-ship-schedule',
        path: `${APP_PREFIX_PATH}/vessel/add-ship-schedule`,
        component: React.lazy(() => import('views/app-views/vessel/ship-schedules/add')),
    },
    {
        key: 'vessel.ships',
        path: `${APP_PREFIX_PATH}/vessel/ships`,
        component: React.lazy(() => import('views/app-views/vessel/ships')),
    },
    {
        key: 'vessel.add-ship',
        path: `${APP_PREFIX_PATH}/vessel/add-ship`,
        component: React.lazy(() => import('views/app-views/vessel/ships/add')),
    },
    {
        key: 'vessel.pointers',
        path: `${APP_PREFIX_PATH}/vessel/pointers`,
        component: React.lazy(() => import('views/app-views/vessel/pointers')),
    },
    {
        key: 'vessel.add-pointer',
        path: `${APP_PREFIX_PATH}/vessel/add-pointer`,
        component: React.lazy(() => import('views/app-views/vessel/pointers/add')),
    },
    {
        key: 'vessel.products',
        path: `${APP_PREFIX_PATH}/vessel/products`,
        component: React.lazy(() => import('views/app-views/vessel/products')),
    },
    {
        key: 'vessel.add-product',
        path: `${APP_PREFIX_PATH}/vessel/add-product`,
        component: React.lazy(() => import('views/app-views/vessel/products/add')),
    },
    {
        key: 'vessel.task-details',
        path: `${APP_PREFIX_PATH}/vessel/task-details`,
        component: React.lazy(() => import('views/app-views/vessel/task-details')),
    },
    {
        key: 'vessel.add-task-detail',
        path: `${APP_PREFIX_PATH}/vessel/add-task-detail`,
        component: React.lazy(() => import('views/app-views/vessel/task-details/add')),
    },
    
]